.chartTitle:hover .maxIcon{
  opacity: 1 !important;
}

.maxIcon {
opacity: 0 !important;
}

rect.disabled {
  fill: transparent !important;
}